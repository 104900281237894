// src/features/products/productsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../../app/assets/shared/baseURL';  // Import baseUrl for API requests
import { mapImageURL } from '../../utils/mapImageURL';

// Async thunk to fetch products from the server
export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {
  const response = await axios.get(`${baseUrl}products`);
  return mapImageURL(response.data);  // Map image URLs before returning the data
});

// Initial state
const initialState = {
  products: [],
  cart: [],
  isLoading: false,
  errMess: null,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const product = action.payload;
      const itemInCart = state.cart.find(item => item.id === product.id);
      if (itemInCart) {
        itemInCart.quantity += 1;
      } else {
        state.cart.push({ ...product, quantity: 1 });
      }
    },
    removeFromCart: (state, action) => {
      const productId = action.payload.id;
      state.cart = state.cart.filter(item => item.id !== productId);
    },
    clearCart: (state) => {
      state.cart = [];
    },
    increaseQuantity: (state, action) => {
      const product = action.payload;
      const itemInCart = state.cart.find(item => item.id === product.id);
      if (itemInCart) {
        itemInCart.quantity += 1; // Increase the quantity
      }
    },
    decreaseQuantity: (state, action) => {
      const product = action.payload;
      const itemInCart = state.cart.find(item => item.id === product.id);
      if (itemInCart && itemInCart.quantity > 1) {
        itemInCart.quantity -= 1; // Decrease the quantity only if greater than 1
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.isLoading = true;
        state.errMess = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.products = action.payload;  // Update products with fetched data
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.errMess = action.error.message;
      });
  }
});

export const { addToCart, removeFromCart, clearCart, increaseQuantity, decreaseQuantity } = productsSlice.actions;
export const selectProducts = (state) => state.products.products;
export const selectCart = (state) => state.products.cart;
export const selectIsLoading = (state) => state.products.isLoading;
export const selectErrMess = (state) => state.products.errMess;

export default productsSlice.reducer;
