import { Container } from "reactstrap";
import SubHeader from '../components/SubHeader';
import Employees from '../components/Employees';
import CenteredHero from "../components/CenteredHeroAbout";
import LogoGrid from "./LogoGrid";
import BrandsHero from "../components/BrandsHero";
import PartnerGrid from "../components/PartnerGrid";

const AboutPage = () => {
    return (
        <Container>
            <SubHeader current='Nosotros' />
            <CenteredHero />
            <Employees />
            <PartnerGrid />
            <BrandsHero />
            <LogoGrid />
        </Container>
    );
};

export default AboutPage; 
