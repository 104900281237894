// src/components/CenteredHero.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const CenteredHero = () => {
    return (
        <Container className="py-5 text-center">
            <Row>
                <Col>
                    <h1 className="display-4">Conoce a Nuestro Equipo</h1>
                    <p className="lead">Somos apasionados por lo que hacemos y estamos comprometidos con tu satisfacción.</p>
                </Col>
            </Row>
        </Container>
    );
};

export default CenteredHero;
