    // src/features/blog/BlogFilter.js
    import React, { useState } from 'react'; //useEffect commented out here
    //import { useSelector, useDispatch } from 'react-redux';
    import BlogList from './BlogList';
    //import { fetchBlogs, selectAllBlogs, selectIsLoading, selectErrMess } from './blogsSlice';
    import { BLOG } from '../../app/assets/img/BLOGPOSTS';  

    const BlogFilter = () => {
        // Commented Redux logic for API usage
        /*
        const dispatch = useDispatch();
        const blogs = useSelector(selectAllBlogs);
        const isLoading = useSelector(selectIsLoading);
        const errMess = useSelector(selectErrMess);
        const [categoryFilter, setCategoryFilter] = useState("All");

        // Fetch blogs when the component mounts
        useEffect(() => {
            dispatch(fetchBlogs());
        }, [dispatch]);
         */

        const blogs = BLOG; // Use local blog data for now
        const [categoryFilter, setCategoryFilter] = useState('All');

        // Filter function based on category
        const filteredBlogs = categoryFilter === "All"
            ? blogs
            : blogs.filter(blog => blog.category === categoryFilter);

        //console.log('Category Filter:', categoryFilter);
        //console.log('Filtered Blogs:', filteredBlogs);

        // Handle loading and error states
        /*
        if (isLoading) {
            return <div>Loading blogs...</div>;
        }

        if (errMess) {
            return <div>Error: {errMess}</div>;
        }
        */
        return (
            <div>
                <div className="mb-4">
                    {/* Buttons for category filtering */}
                    <button className="btn btn-secondary me-2 rounded-pill m-2" onClick={() => setCategoryFilter("All")}>Todo</button>
                    <button className="btn btn-secondary me-2 rounded-pill m-2" onClick={() => setCategoryFilter("Salud")}>Salud</button>
                    <button className="btn btn-secondary me-2 rounded-pill m-2" onClick={() => setCategoryFilter("Construcción")}>Construcción</button>
                    <button className="btn btn-secondary me-2 rounded-pill m-2" onClick={() => setCategoryFilter("Automotriz")}>Automotriz</button>
                    <button className="btn btn-secondary me-2 rounded-pill m-2" onClick={() => setCategoryFilter("Energía")}>Energía</button>
                    <button className="btn btn-secondary me-2 rounded-pill m-2" onClick={() => setCategoryFilter("Alimentación")}>Alimentación</button>
                    <button className="btn btn-secondary me-2 rounded-pill m-2" onClick={() => setCategoryFilter("Comercio")}>Comercio</button>
                </div>
                <BlogList blogs={filteredBlogs} /> {/* Pass filteredBlogs to BlogList */}
            </div>
        );
    };

    export default BlogFilter;
