import { createSlice } from '@reduxjs/toolkit'; //createAsyncThunk commented out here
import { BLOG } from '../../app/assets/img/BLOGPOSTS';
// import axios from 'axios';
// import { mapImageURL } from '../../utils/mapImageURL';  // Import your mapImageURL function
// import { baseUrl } from '../../app/assets/shared/baseURL';  // Use the baseURL for the API

// Fetch blogs from the kauilenergyserver API
//Commented API fetch logic for future use
/* 
export const fetchBlogs = createAsyncThunk('blogs/fetchBlogs', async () => {
  const response = await axios.get(`${baseUrl}blogs`);  // Adjust API endpoint if necessary
  const blogsWithMappedImages = mapImageURL(response.data);  // Use mapImageURL to format image paths
  return blogsWithMappedImages;
});
*/

const initialState = {
  blogs: BLOG,
  isLoading: false,
  errMess: null,
};

const blogsSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    // Add any reducers specific to blogs here if needed
  },
  extraReducers: (builder) => {
    /*
    builder
      .addCase(fetchBlogs.pending, (state) => {
        state.isLoading = true;
        state.errMess = null;
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.blogs = action.payload;
      })
      .addCase(fetchBlogs.rejected, (state, action) => {
        state.isLoading = false;
        state.errMess = action.error.message;
      });
      */
  },
});

export const selectAllBlogs = (state) => state.blogs.blogs;
export const selectIsLoading = (state) => state.blogs.isLoading;
export const selectErrMess = (state) => state.blogs.errMess;

export default blogsSlice.reducer;
