// src/utils/slugify.js
export const slugify = (text) => {
    return text
        .toString()
        .normalize("NFD") // Normalize to Decomposed Form (splits characters and diacritical marks)
        .replace(/[\u0300-\u036f]/g, "") // Remove diacritical marks
        .toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w-]+/g, '')        // Remove all non-word chars
        .replace(/--+/g, '-')           // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
};


//this file is so that we can make userfriendly URLS for the blog