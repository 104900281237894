// HOUSE.js
import candytalesInstall from '../img/generalImg/candytalesInstall.jpg';
import dosRiosVideo from '../img/generalImg/dosRiosVideo.mp4';
import farmPlan from '../img/generalImg/farmPlan.jpeg';
import farmPlan1 from '../img/generalImg/farmPlan1.jpeg';
import konfio from '../img/generalImg/konfioLogo.jpg';

import altamiraLogo from '../img/collaboratorImg/AltamiraLogo.jpg';
import antaiLogo from '../img/collaboratorImg/antaiLogo.png';
import connera from '../img/collaboratorImg/conneraEnergiaRenovable.png';
import enerpointLogo from '../img/collaboratorImg/enerpointLogo.png';
import growattLogo from '../img/collaboratorImg/growattLogo.png';
import huaweiLogo from '../img/collaboratorImg/huaweiLogo.jpg';
import jinkoLogo from '../img/collaboratorImg/jinkoLogo.png';
import vde from '../img/collaboratorImg/logoVDE.svg';
import luxenSolarLogo from '../img/collaboratorImg/luxenSolarLogo.png';
import selloFideA from '../img/collaboratorImg/sello_fideA.jpg';
import seraphimLogo from '../img/collaboratorImg/seraphimLogo.png';

import ideassustentablesLogo from '../img/partnerImg/ideas-sustentables.png';
import SOCLogo from '../img/partnerImg/logo-SOC.jpg';
import rkInmobiliariaLogo from '../img/partnerImg/rkInmobiliaria.png';

import commerceIcon from '../img/generalImg/commerceIcon.png';
import industryIcon from '../img/generalImg/industryIcon.png';

export const partnerImg = [
  { src: ideassustentablesLogo, altText: 'Ideas Sustentables', caption: '', href: 'https://ideasustentables.mx/'},
  { src: SOCLogo, altText: '', caption: 'SOC Asesores', href: 'https://socasesores.com/oficinas/cdmx/cuauhtemoc/ghicar?srsltid=AfmBOopDAxm4hU6qH-PpPR3pjXSnpSHAOvuruXW9l93R0BYluFDR1lrU'},
  { src: rkInmobiliariaLogo, altText: 'RK Inmobiliaria', caption: '', href:'https://www.rkinmobiliaria.mx/' }
];

export const generalImg = [
  { src: candytalesInstall, altText: '', caption: '' },
  { src: farmPlan, altText: '', caption: '' },
  { src: farmPlan1, altText: '', caption: '' },
  { src: konfio, altText: '', caption: '' },
];

export const video = [
  { src: dosRiosVideo, altText: '', caption: '' },
]

export const collaboratorImg = [
  { src: altamiraLogo, altText: 'Altamira' },
  { src: antaiLogo, altText: 'Antai' },
  { src: connera, altText: 'Connera Energia Renovable' },
  { src: enerpointLogo, altText: 'Enerpoint' },
  { src: growattLogo, altText: 'Growatt' },
  { src: huaweiLogo, altText: 'Huawei' },
  { src: jinkoLogo, altText: 'Jinko' },
  { src: vde, altText: 'VDE' },
  { src: luxenSolarLogo, altText: 'Luxen Solar' },
  { src: selloFideA, altText: 'Sello Fide A' },
  { src: seraphimLogo, altText: 'Seraphim' },
];

export const cotizadorImg = [
  { src: commerceIcon, altText: 'commerce' },
  { src: industryIcon, altText: 'industry' },

];
