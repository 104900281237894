// src/components/Hero.js
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/HeroFrontPage.css'; // CSS for the background image
import ReactGA from 'react-ga4';

    // Event handler for button click for Goggle Analytics

// Event handler for button click for Google Analytics
const handleButtonClick = (buttonName) => {
  ReactGA.event({
      category: 'User Interaction', // Category for all button clicks
      action: `Clicked ${buttonName} Button`, // Action varies depending on the button
      label: 'Form Buttons' // Optional label to provide additional context
  });
};

const Hero = () => {
  return (
    <div className="hero-container"> {/* Removed padding and margin classes */}
      <Container fluid className="p-0"> {/* Removed padding from Container */}
        <h1 className="display-4 text-body-emphasis hero-heading">
          Paneles solares para manufactura, agricultura y comercio
        </h1>
        <Row className="justify-content-center">
          <Col lg={6}>
            <p className="lead mb-4 hero-lead">
              Diseñamos, financiamos e instalamos soluciones fotovoltaicas para organizaciones con alto consumo de electricidad, dando ahorros mayores en sus gastos energéticos. Además, construimos residencias con una solución de almacenaje de electricidad.
            </p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5 hero-buttons">
              <Button 
                variant="primary" 
                size="lg" 
                className="px-4 me-sm-3 rounded-pill"
                href="/Contacto"
                onClick={() => handleButtonClick('Cotiza Planta Button')} /*Add onClick handler for google analytics */
                >
                Cotiza planta solar
              </Button>
              <Button
                variant="primary"
                size="lg"
                className="px-4 rounded-pill"
                href="/casa-kauil"
                onClick={() => handleButtonClick('Explorar Casa Button')} /*Add onClick handler for google analytics */
                >
                Explorar Casa Kauil
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <hr className="featurette-divider" />
    </div>
  );
};

export default Hero;