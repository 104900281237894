import React from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Button, Container, Row, Col, FormGroup, Label } from 'reactstrap';
import '../../css/PowerConsumptionForm.css';
const PowerConsumptionFormMonthly = () => {
  // Define the initial values and validation schema
  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    rows: [
      { kWh: '', amountPaid: '' },
      { kWh: '', amountPaid: '' },
      { kWh: '', amountPaid: '' },
    ],
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('El primer nombre es requerido'),
    lastName: Yup.string().required('El apellido es requerido'),
    phone: Yup.string()
      .required('El numero es requerido')
      .matches(/^[0-9]{10}$/, 'El numero debe tener 10 digitos'),
    email: Yup.string().email('Correo no valido').required('El correo es requerido'),
    rows: Yup.array().of(
      Yup.object({
        kWh: Yup.number().required('kWh es requerido').positive('kWh tiene que ser positivo'),
        amountPaid: Yup.number().required('Monto requerido').positive('El monto debe ser positivo'),
      })
    ),
  });

  // Handle form submission
  const handleSubmit = (values) => {
    // Map the form data to match the API structure
    const data = {
      personalInfo: {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        email: values.email,
      },
      consumption: values.rows.map((row, index) => ({
        month: index + 1, // Month is calculated as index + 1
        kWh: row.kWh,
        amountPaid: row.amountPaid,
      })),
    };

    // Replace with the URL from your Google Apps Script deployment
    const url = 'https://script.google.com/macros/s/AKfycby6ywJum5tQkJJ-mnJWFV2NwSOMSkMB-DiBX_ik4Uv6kYBe43rZfstfoaZoM3LqhUhmUw/exec';

    // Send data to the Google Apps Script WebApp API with the redirect attribute
    fetch(url, {
      redirect: 'follow', // Ensure the fetch follows redirects
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain;charset=utf-8', // Adjusted to 'text/plain'
      },
      body: JSON.stringify(data), // Send the data as a string
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        alert('Data sent successfully!');
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Error sending data');
      });
  };

  return (
    <>
    <Container fluid className="text-center bg-light py-5 my-4 mx-3">
            <h3 className="display-5">Ten a la mano la segunda página de su recibo de CFE</h3>
            <p className="text-muted">Completa el formulario y recibirás una cotización personalizada directamente en tu correo. La cotización se ajusta a tus necesidades, calculando el tamaño de tu sistema según tu consumo eléctrico mensual.</p>
    </Container>
    
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched }) => (
          <Form>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="firstName">Primer Nombre</Label>
                  <Field
                    name="firstName"
                    id="firstName"
                    className={`form-control ${touched.firstName && errors.firstName ? 'is-invalid' : ''}`}
                  />
                  {touched.firstName && errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="lastName">Apellido</Label>
                  <Field
                    name="lastName"
                    id="lastName"
                    className={`form-control ${touched.lastName && errors.lastName ? 'is-invalid' : ''}`}
                  />
                  {touched.lastName && errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="phone">Teléfono</Label>
                  <Field
                    name="phone"
                    id="phone"
                    className={`form-control ${touched.phone && errors.phone ? 'is-invalid' : ''}`}
                  />
                  {touched.phone && errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="email">Correo Electrónico</Label>
                  <Field
                    name="email"
                    id="email"
                    type="email"
                    className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                  />
                  {touched.email && errors.email && <div className="invalid-feedback">{errors.email}</div>}
                </FormGroup>
              </Col>
            </Row>
            <FieldArray name="rows">
              {({ push, remove }) => (
                <>
                  <Row>
                    <Col><strong></strong></Col>
                    <Col><strong></strong></Col>
                    <Col><strong></strong></Col>
                  </Row>

                  {values.rows.map((row, index) => (
                    <Row key={index}>
                      {/* Static Month Number Column */}
                      <Col md={2}>
                        <FormGroup>
                          <Label>Periodo</Label>
                          <div className="form-control" disabled>{index + 1}</div>
                        </FormGroup>
                      </Col>

                      {/* Editable kWh Column */}
                      <Col md={5}>
                        <FormGroup>
                          <Label for={`rows.${index}.kWh`}>Consumo total KWh</Label>
                          <Field
                            type="number"
                            name={`rows.${index}.kWh`}
                            id={`rows.${index}.kWh`}
                            className={`form-control ${touched.rows?.[index]?.kWh && errors.rows?.[index]?.kWh ? 'is-invalid' : ''}`}
                          />
                            {touched.rows?.[index]?.kWh && errors.rows?.[index]?.kWh && (
                            <div className="invalid-feedback">{errors.rows[index].kWh}</div>
                          )}
                        </FormGroup>
                      </Col>

                      {/* Editable Amount Paid Column */}
                      <Col md={5}>
                        <FormGroup>
                          <Label for={`rows.${index}.amountPaid`}>Precio Medio/kWh</Label>
                          <Field
                            type="number"
                            name={`rows.${index}.amountPaid`}
                            id={`rows.${index}.amountPaid`}
                            className={`form-control ${touched.rows?.[index]?.amountPaid && errors.rows?.[index]?.amountPaid ? 'is-invalid' : ''}`}
                          />
                            {touched.rows?.[index]?.amountPaid && errors.rows?.[index]?.amountPaid && (
                            <div className="invalid-feedback">{errors.rows[index].amountPaid}</div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  ))}

                  <Button
                    type="button"
                    onClick={() => {
                      if (values.rows.length < 12) {
                        push({ kWh: '', amountPaid: '' });
                      }
                    }}
                    color="primary"
                    size="sm"
                    className="px-4 rounded-pill"
                    href=""
                    style={{ marginTop: '1rem', marginBottom: '1rem', marginLeft: '1rem', marginRight: '1rem' }}
                    > 
                    Agregar fila
                  </Button>

                  {values.rows.length > 3 && (
                    <Button
                      type="button"
                      onClick={() => remove(values.rows.length - 1)}
                      color="danger"
                      size='sm'
                      className="px-4 rounded-pill"
                      style={{ marginTop: '1rem', marginBottom: '1rem', marginLeft: '1rem', marginRight: '1rem' }}
                      >
                      Borrar ultima fila
                    </Button>
                  )}

                  <Button 
                    type="submit" 
                    color="success" 
                    className="px-4 rounded-pill"
                    size="sm"
                    style={{ marginTop: '1rem', marginBottom: '1rem', marginLeft: '1rem', marginRight: '1rem' }}
                    >
                    Enviar
                  </Button>
                </>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </Container>
    </>
  );
};

export default PowerConsumptionFormMonthly;
