import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navbar, NavbarBrand, Collapse, Nav, NavItem, Button, Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { selectCart } from '../features/products/productSlice';
import KauilLogo from '../app/assets/img/300X87-ICONO-NARANJA.png';
//import UserLoginForm from '../features/user/userLoginForm'
//import UserSignupForm from '../features/user/userSignupForm';
import UserAvatar from '../features/user/userAvatar';
import { isAuthenticated, userLogout, validateLogin } from '../features/user/userSlice';

import '../css/Header.css'; // Import the CSS file

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const cart = useSelector(selectCart);
    const cartItemCount = cart.length;
    const auth = useSelector(isAuthenticated); // Check if user is authenticated
    const dispatch = useDispatch();
    console.log('Is user authenticated?', auth); // Check auth state

    // Validate user login on component mount
    useEffect(() => {
        dispatch(validateLogin());
    }, [dispatch]);

    const handleNavLinkClick = () => {
        setMenuOpen(false);
    };

    // Conditionally render user options based on authentication status
    const userOptions = auth ? (
        <>
            <span className='navbar-text ml-auto'>
                <Button
                    outline
                    onClick={() => dispatch(userLogout())}
                    style={{
                        color: 'white',
                        border: '1px solid white',
                        margin: '5px'
                    }}
                >
                    <i className='fa fa-sign-out fa-lg' /> Logout
                </Button>
            </span>
            <UserAvatar />
        </>
    ) : (
        <>
            {/* Commenting out login and signup for now */}
            {/* <UserLoginForm /> */}
            {/* <UserSignupForm /> */}
        </>
    );

    return (
        <Navbar dark style={{ backgroundColor: '#1E2146' }} sticky='top' expand='md'>
            <div className='container-fluid d-flex justify-content-between align-items-center'>
                <NavbarBrand className='ms-0' href='/'>
                    <img src={KauilLogo} alt='kauil logo' className='kauil-logo' />
                </NavbarBrand>
                {!menuOpen && (
                    <button
                        className="navbar-toggler order-md-1"
                        type="button"
                        aria-label="Toggle navigation"
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                )}
                <Collapse isOpen={menuOpen} navbar className='order-md-3'>
                    <Nav className='ms-auto' navbar>
                        <NavItem>
                            <NavLink className='nav-link' to='/' onClick={handleNavLinkClick}>
                                <i className='fa fa-home fa-lg' /> Home
                            </NavLink>
                        </NavItem>
                        {/*} 
                        <NavItem>
                            <NavLink className='nav-link' to='/Cotizador' onClick={handleNavLinkClick}>
                                <i className='fa fa-calculator fa-lg' /> Cotizador
                            </NavLink>
                        </NavItem>
                        */}
                        <NavItem>
                            <NavLink className='nav-link' to='/Casa-Kauil' onClick={handleNavLinkClick}>
                                <i className='fa fa-hammer fa-lg' /> Casa Kauil
                            </NavLink>
                        </NavItem>
                        {/* 
                        <NavItem>
                            <NavLink className='nav-link' to='/Tienda' onClick={handleNavLinkClick}>
                                <i className='fa fa-shopping-cart fa-lg' /> Tienda
                            </NavLink>
                        </NavItem>
                        */}
                        <NavItem>
                            <NavLink className='nav-link' to='/Nosotros' onClick={handleNavLinkClick}>
                                <i className='fa fa-info fa-lg' /> Nosotros
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className='nav-link' to='/Contacto' onClick={handleNavLinkClick}>
                                <i className='fa fa-address-card fa-lg' /> Contacto
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className='nav-link' to='/Blog' onClick={handleNavLinkClick}>
                                <i className='fa fa-book fa-lg' /> Blog
                            </NavLink>
                        </NavItem>
                        {cartItemCount > 0 && (
                            <NavItem>
                                <NavLink className='nav-link' to='/checkout' onClick={handleNavLinkClick}>
                                    <i className='fa fa-shopping-cart fa-lg' />
                                    <Badge color='success' pill className='ms-1'>
                                        {cartItemCount}
                                    </Badge>
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                    {userOptions} {/* Render login/signup or logout based on authentication */}
                </Collapse>
            </div>
        </Navbar>
    );
};

export default Header;
