import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Button, Container, Row, Col } from 'reactstrap';
import PowerConsumptionForm from '../features/forms/PowerConsumptionFormBimonthly';
import PowerConsumptionFormMonthly from '../features/forms/PowerConsumptionFormMonthly';
import { CSSTransition } from 'react-transition-group';
import '../css/CotizadorCard.css'; // Add CSS transitions here
import { cotizadorImg } from '../app/assets/shared/IMAGES'; // Importing the image array


const CotizadorCard = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [cardsVisible, setCardsVisible] = useState(true);

    const handleCardClick = (option) => {
        setSelectedOption(option);
        setCardsVisible(false);
    };

    return (
        <Container className="mt-5">
            <CSSTransition
                in={cardsVisible}
                timeout={300}
                classNames="fade"
                unmountOnExit
            >
                <Row className="justify-content-center">
                    <Col md="6" lg="5" className="mb-3">
                    <Card className="text-center" style={{ width: '100%', height: '100%'}}>
                            <CardBody>
                                <CardTitle tag="h5">Industria</CardTitle>
                                {/* Use cotizadorImg to access the industryIcon */}
                                <img 
                                    src={cotizadorImg[1].src} 
                                    alt="" 
                                    style={{ 
                                        borderRadius: '0.375rem', 
                                        width: '20%',
                                        marginBottom: '2rem',
                                        marginTop: '2rem',
                                    }} // Optional: add rounded corners if needed
                                />
                                <p style={{ textAlign: 'center', width: '80%', margin: '0 auto' }}>
                                    Selecciona esta opción si estas pagando MENSUALMENTE a CFE.
                                </p>
                                <Button 
                                    className="px-4 rounded-pill"
                                    color="success"
                                    onClick={() => handleCardClick('monthly')}
                                    style={{ 
                                        marginBottom: '1rem',
                                        marginTop: '1rem',
                                    }}>
                                    Seleccionar
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6" lg="5" className="mb-3">
                        <Card className="text-center" style={{ width: '100%', height: '100%'}}>
                            <CardBody>
                                <CardTitle tag="h5">Comercio</CardTitle>
                                    {/* Use cotizadorImg to access the industryIcon */}
                                    <img 
                                    src={cotizadorImg[0].src} 
                                    alt="" 
                                    style={{ 
                                        borderRadius: '0.375rem', 
                                        width: '20%',
                                        marginBottom: '2rem',
                                        marginTop: '2rem',
                                    }} // Optional: add rounded corners if needed
                                />
                                <p style={{ textAlign: 'center', width: '80%', margin: '0 auto' }}>
                                    Selecciona esta opción si estas pagando BIMESTRALMENTE a CFE.
                                </p>
                                <Button 
                                    className="px-4 rounded-pill" 
                                    color="success" 
                                    onClick={() => handleCardClick('bimonthly')}
                                    style={{ 
                                        marginBottom: '1rem',
                                        marginTop: '1rem',
                                    }}
                                    >
                                    Seleccionar
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </CSSTransition>
            <CSSTransition
                in={selectedOption === 'bimonthly'}
                timeout={1000}
                classNames="fade"
                unmountOnExit
            >
                <Row className="justify-content-center mt-4">
                    <PowerConsumptionForm />
                </Row>
            </CSSTransition>

            <CSSTransition
                in={selectedOption === 'monthly'}
                timeout={1000}
                classNames="fade"
                unmountOnExit
            >
                <Row className="justify-content-center mt-4">
                    <PowerConsumptionFormMonthly />
                </Row>
            </CSSTransition>
        </Container>
    );
};

export default CotizadorCard;
