import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
const TrackPageView = () => {
    const location = useLocation();

    useEffect(() => {
        // Send the pageview to Google Analytics
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname + location.search,
            title: document.title // Optionally, send the title of the page
        });

        // Track UTM parameters from the URL
        const urlParams = new URLSearchParams(location.search);
        const utmSource = urlParams.get('utm_source');
        const utmMedium = urlParams.get('utm_medium');
        const utmCampaign = urlParams.get('utm_campaign');

        if (utmSource) {
            ReactGA.event({
                category: 'UTM Tracking',
                action: `Visited from ${utmSource}`,
                label: `${utmMedium ? `Medium: ${utmMedium}` : ''} ${utmCampaign ? `Campaign: ${utmCampaign}` : ''}`
            });
        }
    }, [location]);

    return null;
};

export default TrackPageView;