// src/features/blog/BlogList.js
import React from 'react';
import { Col, Row } from 'reactstrap';
import BlogCard from './BlogCard';

// BlogList now accepts blogs as a prop
const BlogList = ({ blogs }) => {
    //console.log('Blogs in BlogList:', blogs); // For debugging

    if (!blogs.length) {
        return <div>No blogs available for this category.</div>;
    }

    return (
        <Row>
            {blogs.map((blog) => (
                <Col md="4" className="mb-4" key={blog.id}>
                    <BlogCard blog={blog} />
                </Col>
            ))}
        </Row>
    );
};

export default BlogList;
