// src/components/ProductList.js

import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import ProductCard from './ProductCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts, selectProducts, selectIsLoading, selectErrMess } from '../features/products/productSlice';

const ProductList = () => {
  const dispatch = useDispatch();

  const products = useSelector(selectProducts);
  const isLoading = useSelector(selectIsLoading);
  const errMess = useSelector(selectErrMess);

  // Dispatch fetchProducts when the component mounts
  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  if (isLoading) {
    return <div>Loading products...</div>;
  }

  if (errMess) {
    return <div>Error: {errMess}</div>;
  }

  return (
    <Row>
      {products.map((product) => (
        <Col md={4} lg={3} key={product.id} className="mb-4">
          <ProductCard product={product} />
        </Col>
      ))}
    </Row>
  );
};

export default ProductList;
