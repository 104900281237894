// src/components/BrandsHero.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const BrandsHero = () => {
    return (
        <Container className="py-5 text-center">
            <Row>
                <Col>
                    <h1 className="display-4">Conoce a las marcas que manejamos</h1>
                    <p className="lead">Usamos una gama amplia de marcas lideres en energía renovable para asegurar la calidad de tú instalación.</p>
                </Col>
            </Row>
        </Container>
    );
};

export default BrandsHero;
