import React from 'react'
import CotizadorCard from '../components/CotizadorCard';
const CotizadorPage = () => {

  return (
    <div className="App">
      <CotizadorCard />
    </div>
  );
}

export default CotizadorPage;
