import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
//import { Container } from 'reactstrap';
//import SubHeader from '../components/SubHeader';
import Hero from '../components/heroFrontPage';
import Featurette from '../components/FeaturetteFrontPage';
import '../css/HomePage.css';
import PartnerGrid from '../components/PartnerGrid';


  const HomePage = () => {
    return (
        <div className='homepage-container'>
            <Hero />
            <Featurette />
            <PartnerGrid />
        </div>
    );
};
  
  export default HomePage;
