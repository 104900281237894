import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from 'emailjs-com';
import { FormGroup, Label, Col, InputGroup, InputGroupText, Button } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { validateContactForm } from '../../utils/validateContactForm';
import '../../css/ContactForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga4';

const ContactForm = () => {
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const handleSubmit = (values, { resetForm }) => {
        if (!recaptchaToken) {
            alert('Por favor, completa el CAPTCHA.');
            return;
        }

        console.log('form values', values);

        // EmailJS configuration
        const serviceID = 'service_91pcwfw';
        const templateID = 'template_e52vwxj';
        const userID = 'WC2XdzdkvZ6MbcQkn';

        const emailParams = {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNum: values.phoneNum,
            email: values.email,
            contactType: values.contactType,
            feedback: values.feedback,
        };

        emailjs
            .send(serviceID, templateID, emailParams, userID)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('¡Se envió su mensaje!');
                resetForm(); // Reset the form after success
            })
            .catch((error) => {
                console.log('FAILED...', error);
                alert('Hubo un error enviando su mensaje. Intenta más tarde o agenda una renunion con nosotros.');
            });
    };

    //function MyForm() {
    // Event handler for button click for Goggle Analytics
    const handleButtonClick = () => {
        ReactGA.event({
            category: 'Form',
            action: 'Clicked Enviar Contact Form Button',
            label: 'Contact Form'
        });
    };

    return (
        <Formik
            initialValues={{
                firstName: '',
                lastName: '',
                phoneNum: '',
                email: '',
                agree: false,
                contactType: 'Por Telefono',
                feedback: '',
            }}
            onSubmit={handleSubmit}
            validate={validateContactForm}
        >
            <Form>
                <FormGroup row className="justify-content-center">
                    <Col md={{ size: 10, offset: 2 }}>
                        <InputGroup>
                            <InputGroupText className="input-group-icon" style={{ height: 40 }}>
                                <FontAwesomeIcon icon={faUser} />
                            </InputGroupText>
                            <Field
                                name="firstName"
                                placeholder="Primer Nombre"
                                className="form-control"
                                style={{ height: 40 }}
                            />
                        </InputGroup>
                        <ErrorMessage name="firstName">
                            {(msg) => <p className="text-danger">{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>

                <FormGroup row className="justify-content-center">
                    <Col md={{ size: 10, offset: 2 }}>
                        <InputGroup>
                            <InputGroupText className="input-group-icon" style={{ height: 40 }}>
                                <FontAwesomeIcon icon={faUser} />
                            </InputGroupText>
                            <Field
                                name="lastName"
                                placeholder="Apellido"
                                className="form-control"
                                style={{ height: 40 }}
                            />
                        </InputGroup>
                        <ErrorMessage name="lastName">
                            {(msg) => <p className="text-danger">{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>

                <FormGroup row className="justify-content-center">
                    <Col md={{ size: 10, offset: 2 }}>
                        <InputGroup>
                            <InputGroupText className="input-group-icon" style={{ height: 40 }}>
                                <FontAwesomeIcon icon={faPhone} />
                            </InputGroupText>
                            <Field
                                name="phoneNum"
                                placeholder="Teléfono"
                                className="form-control"
                                style={{ height: 40 }}
                            />
                        </InputGroup>
                        <ErrorMessage name="phoneNum">
                            {(msg) => <p className="text-danger">{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>

                <FormGroup row className="justify-content-center">
                    <Col md={{ size: 10, offset: 2 }}>
                        <InputGroup>
                            <InputGroupText className="input-group-icon" style={{ height: 40 }}>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </InputGroupText>
                            <Field
                                name="email"
                                placeholder="Correo Electrónico"
                                className="form-control"
                                style={{ height: 40 }}
                            />
                        </InputGroup>
                        <ErrorMessage name="email">
                            {(msg) => <p className="text-danger">{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>

                <FormGroup row className="justify-content-center">
                    <Label check md={{ size: 4, offset: 2 }}>
                        <Field
                            name="agree"
                            type="checkbox"
                            className="form-check-input"
                        />{' '}
                        Podemos contactarte?
                    </Label>
                    <Col md="4">
                        <Field
                            name="contactType"
                            as="select"
                            className="form-control"
                        >
                            <option>Por Telefono</option>
                            <option>Por Correo</option>
                        </Field>
                    </Col>
                </FormGroup>

                <FormGroup row className="justify-content-center">
                    <Col md={{ size: 10, offset: 2 }}>
                        <Field
                            name="feedback"
                            placeholder="Tu comentario o pregunta"
                            as="textarea"
                            rows="12"
                            className="form-control"
                        />
                    </Col>
                </FormGroup>

                {/* reCAPTCHA */}
                <FormGroup row className="justify-content-center">
                    <Col md={{ size: 10, offset: 2 }} className="text-center">
                        <ReCAPTCHA
                            sitekey="6LcsH5YqAAAAAORsxP0Gx4n_COXrpFD_STFV8zdt" // Replace with your reCAPTCHA site key
                            onChange={(token) => setRecaptchaToken(token)}
                            onExpired={() => setRecaptchaToken(null)} // Handle token expiration
                        />
                    </Col>
                </FormGroup>

                <FormGroup row className="justify-content-center">
                    <Col md={{ size: 10, offset: 2 }}>
                        <Button 
                            type="submit" 
                            color="outline-primary" 
                            className="m-2 rounded-pill"
                            onClick={handleButtonClick} > {/*Add onClick handler for google analytics */}
                            Enviar
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
        </Formik>
    );
};

export default ContactForm;
