import React, { useState } from 'react';

const FloatingCalButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      {/* Floating Button */}
      <button
        style={{
          position: 'fixed',
          bottom: '25px',
          left: '20px',
          height: '48px',
          backgroundColor: '#0077FF',
          color: '#fff',
          padding: '10px 20px',
          borderRadius: '50px',
          border: 'none',
          cursor: 'pointer',
          fontSize: '16px',
          zIndex: 1000,
        }}
        onClick={handleClick}
      >
        Agendar una reunión
      </button>

 {/* Cal.com Modal */}
 {isVisible && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 2000, // Higher z-index to ensure it appears above the header
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'auto', // Allow scrolling if content is too large
          }}
          onClick={() => setIsVisible(false)} // Close modal on click outside iframe
        >
          <div
            style={{
              position: 'relative',
              maxWidth: '90%', // Limit the maximum width of the iframe
              maxHeight: '90%', // Limit the maximum height of the iframe
              width: '80%', // Adjust to desired width
              height: '80%', // Adjust to desired height
            }}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside iframe
          >
            <iframe
              title="Cal.com"
              src="https://cal.com/kauil-energy-cty0c8?locale=es"  // Replace with your Cal.com scheduling link
              style={{
                width: '100%', // Use the full width of the container
                height: '100%', // Use the full height of the container
                border: 'none',
                borderRadius: '8px',
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FloatingCalButton;
