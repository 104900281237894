export const mapImageURL = (arr) => {
    return arr.map((item) => {
        // Construct the path dynamically and require the image
        const imagePath = require(`../app/assets/img/${item.image}`);
        return {
            ...item,
            image: imagePath
        };
    });
};
