// src/components/LogoCarousel.js
import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import '../css/LogoCarousel.css'; // Custom CSS for hover effect and styling
import { collaboratorImg } from '../app/assets/shared/IMAGES';

const LogoGrid = () => {
    return (
        <Container className="py-5">
            <Row className="justify-content-center">
                {collaboratorImg.map((img, index) => (
                    <Col key={index} md={2} xs={4} className="text-center">
                        <Image 
                            src={img.src} 
                            alt={img.altText || `Collaborator ${index + 1}`} 
                            className="logo-image"
                            style={{ width: '150px', height: '150px', objectFit: 'contain' }} 
                            fluid 
                        />
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default LogoGrid;