// src/components/LogoCarousel.js
import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import '../css/LogoCarousel.css'; // Custom CSS for hover effect and styling
import { partnerImg } from '../app/assets/shared/IMAGES';
import ReactGA from 'react-ga4';

// Event handler for button click for Google Analytics
const handleButtonClick = (buttonName) => {
    ReactGA.event({
        category: 'User Interaction', // Category for all button clicks
        action: `Clicked ${buttonName} Button`, // Action varies depending on the button
        label: 'Form Buttons' // Optional label to provide additional context
    });
  };

const PartnerGrid = () => {
    return (
        <Container className="py-5 text-center">
            <Row>
                <Col>
                    <h1 className="display-4">Conoce nuestros colaboradores</h1>
                    <p className="lead">Trabajamos con inmobiliarias, financieras y ONGs para satisfacer sus necesidades.</p>
                </Col>
            </Row>
        
            <Row className="justify-content-center">
                {partnerImg.map((img, index) => (
                    <Col key={index} md={3} xs={6} className="text-center"> {/* Adjust column sizes */}
                        <a href={img.href} target="_blank" rel="noopener noreferrer">
                            <Image 
                            src={img.src} 
                            alt={img.altText || `Collaborator ${index + 1}`} 
                            className="logo-image"
                            style={{ width: '200px', height: '200px', objectFit: 'contain' }} 
                            fluid
                            onClick={() => handleButtonClick(`${img.href} Button`)}
                            />
                        </a>
                    </Col>
                ))}
            </Row>  
        </Container>
    );
};

export default PartnerGrid;