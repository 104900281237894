// src/components/Employees.js
import React from 'react';
import { Row, Col, Card, CardImg, CardBody, CardTitle, CardText } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Employees.css'; // Import the CSS file

import Asger from '../app/assets/img/employees/asger.jpg'; // Correct relative path
import Ricardo from '../app/assets/img/employees/Perfil_RZC2.png'; // Correct relative path
import Marcos from '../app/assets/img/employees/marcos.jpeg'; // Correct relative path
import ElizabethGomez from '../app/assets/img/employees/elizabeth.jpg'; // Correct relative path
import Cristina from '../app/assets/img/employees/cristina.jpg'; // Correct relative path
const Employees = () => {
  const teamMembers = [
    {
      name: 'Asger Victor Hansen',
      imgSrc: Asger,
      description: 'Dueño, ventas. Licenciado en Economía de Gettysburg College y Maestro en Gestión Ambiental enfocado en energía y emprendimiento de Duke University.',
    },
    {
      name: 'Elizabeth Gomez',
      imgSrc: ElizabethGomez,
      description: 'Licenciada en derecho de Universidad de Guadalajara y Abogada Corporativa.',
    },
    {
      name: 'Marco Rafael Godoy',
      imgSrc: Marcos,
      description: 'Contabilidad.',
    },
    {
        name: 'Ricardo Zaragoza Castillo',
        imgSrc: Ricardo,
        description: 'Asesor en estrategía y analisis renovable. Cofundador. Profesor en MIT. Licenciado en Economía de UNAM, y Maestro en Energía Renovable de Duke University ',
    },
    {
        name: 'Cristina Valdivieso',
        imgSrc: Cristina,
        description: 'Asesora en arquitectura, Licienciada en Arquitectura de Bennington College',
    },

  ];

  return (
    <Row className="employee-row text-center"
    style={{ position: 'relative', zIndex: 1 }}>
      {teamMembers.map((member, index) => (
        <Col md={4} key={index} className="mb-4"> 
          <Card className="border-0 rounded-4 text-center">
            <CardImg
              variant="top"
              src={member.imgSrc}
              className="rounded-circle img-fluid employee-img mx-auto"
              style={{ width: '150px', height: '150px', objectFit: 'cover', zIndex: 1 }}
            />
            <CardBody>
              <CardTitle as="h5">{member.name}</CardTitle>
              <CardText>{member.description}</CardText>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Employees;
